<!-- Modal -->
<div *ngIf="showMailVerificationMenu" class="modal-dialog modal-dialog-centered mail-modal">
    <div class="modal-content">
        <div class="modal-body">
            <div class="profile-section">
                <div class="profile-outer">
                    <div class="lockicon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="37" viewBox="0 0 31 37" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M15.4999 0.437988C10.4771 0.437988 6.40529 4.50979 6.40529 9.53261V9.53715H5.67119C2.64048 9.53715 0.183594 11.994 0.183594 15.0247V30.8548C0.183594 33.8855 2.64048 36.3424 5.6712 36.3424H25.3305C28.3612 36.3424 30.8181 33.8855 30.8181 30.8548V15.0248C30.8181 11.994 28.3612 9.53715 25.3305 9.53715H24.5945V9.53261C24.5945 4.50979 20.5227 0.437988 15.4999 0.437988ZM21.7226 9.53715V9.53261C21.7226 6.09595 18.9366 3.30998 15.4999 3.30998C12.0632 3.30998 9.27728 6.09595 9.27728 9.53261V9.53715H21.7226ZM19.3319 22.9376C19.3319 25.0525 17.6174 26.7669 15.5026 26.7669C13.3877 26.7669 11.6733 25.0525 11.6733 22.9376C11.6733 20.8227 13.3877 19.1083 15.5026 19.1083C17.6174 19.1083 19.3319 20.8227 19.3319 22.9376Z"
                                fill="#006736" />
                        </svg>
                    </div>
                </div>
            </div>
            <div class="mail-verfication-wrap" id="step1" *ngIf="showStepOne || !user?.['is_valid'] || isEditing">
                <h3>Enter details to proceed</h3>
                <p>To get notified about the purchase, sell, negotiation kindly fill the below details.</p>
                <form [formGroup]="emailForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="">First name.<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" placeholder="Ex: John" formControlName="first_name"
                            [ngClass]="{ 'is-invalid': submitted && f['first_name'].errors }">
                        <div class="input--error"
                            *ngIf="(f &&f['first_name'].touched || submitted) &&f&& f['first_name']?.errors?.['required']">
                            First name is required.*
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="">Last name.<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" placeholder="Ex: Doe" formControlName="last_name"
                            [ngClass]="{ 'is-invalid': submitted && f['last_name'].errors }">
                        <div class="input--error"
                            *ngIf="(f &&f['last_name'].touched || submitted) &&f&& f['last_name']?.errors?.['required']">
                            Last name is required.*
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="">Email Address.<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" formControlName="email" placeholder="Ex: john@mail.com"
                            [ngClass]="{ 'is-invalid': submitted && f['email'].errors }">
                        <div class="input--error"
                            *ngIf="(f &&f['email'].touched || submitted) && f&&f['email'].errors?.['required']">
                            Email is required.*
                        </div>
                        <div class="input--error"
                            *ngIf="(f&& f['email'].touched || submitted) && f&&f['email'].errors?.['forbidUppercase']">
                            Capital letters not allowed.*
                        </div>
                        <div class="input--error"
                            *ngIf="(f&& f['email'].touched || submitted) && f&&f['email'].errors?.['email']">
                            Enter valid email address.*
                        </div>
                    </div>
                    <!-- Checkbox : Privacy Policy and Agreement (SIGNATURE REQUIRED) -->
                    <div class="form-group mb-3">
                        <label>
                            <input type="checkbox" [checked]="is_Checked['agreement_checkbox']"
                                (click)="triggerSignatureRetrieval($event)">
                            <span class="pl-2">I agree to the
                                <a rel="noopener" target="_blank"
                                    href="{{commonService?.docUrls?.privacy_policy_url}}">privacy policy</a>
                                and
                                <a target="_blank" rel="noopener"
                                    href="{{commonService?.docUrls?.user_agreement_url}}">terms of
                                    service</a>.
                            </span>
                        </label>
                        <div class="signature-container" *ngIf="isPendingSignature">
                            <div class="alert-signature ">
                                <div class="flex">
                                    <div class="flex-shrink-0">
                                        <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 alert-svg">
                                            <path clip-rule="evenodd"
                                                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                                fill-rule="evenodd"></path>
                                        </svg>
                                    </div>
                                    <div class="alert-prompt-wrap">
                                        <span class="text-sm text-yellow-700 blink-soft">
                                            A signing request is pending. Please open
                                            MetaMask to review
                                            and complete the
                                            process.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex gap-3 flex-wrap"
                        [ngClass]="{'justify-content-center':isCancelOption , 'justify-content-between':!isCancelOption}">
                        <button type="button" (click)="closeStepOne()" class="onboard_cancel" data-bs-dismiss="modal"
                            *ngIf="!isCancelOption">Cancel.</button>
                        <button [disabled]="submitted" type="submit" class="onboard_verify">{{submitted
                            ?'Processing...':'Verify
                            Email'}}</button>
                    </div>
                </form>
            </div>
            <div class="mail-verfication-wrap" id="step2"
                *ngIf="(showStepTwo || user?.['is_valid'] && !isEditing) && !showStepThree">
                <h3>Check your email</h3>
                <p>We have sent you an email to verify. Check your inbox for more information about the verification.
                </p>
                <form>
                    <div class="form-group position-relative">
                        <label for="">Email Address</label>
                        <input type="text" class="form-control" [value]="user['email']" readonly>
                        <div class="editIcon">
                            <img (click)="onEdit()" src="assets/images/edit.svg" alt="edit icon" width="18" height="18"
                                class="cursor-pointer">
                        </div>
                    </div>
                    <p>Didn't receive the email? <a class="cursor-pointer" [class.disabled]="isDisabled"
                            [attr.disabled]="isDisabled ? true : null" (click)="resendVerificationEmail()">Resend
                            mail.</a></p>
                    <a class="onboard_cancel d-block text-center" (click)="closeModal.emit()">Close.</a>
                </form>
            </div>
            <div class="mail-verfication-wrap" id="step3" *ngIf="showStepThree">
                <div class="text-center">
                    <img src="assets/images/success-3.svg" alt="success" width="90" height="90" class="mb-3">
                </div>
                <h3>Account verified.</h3>
                <p>Your account has been successfully verified. You can now enjoy full access to all features.</p>
                <div class="pt-4">
                    <a (click)="showStepThree=false;closeModal.emit();"
                        class="onboard_cancel d-block text-center">Close.</a>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!showMailVerificationMenu" class="modal-dialog modal-dialog-centered emailnewsletter-modal">
    <div class="modal-content">
        <div class="modal-head">
            <div class="profile-section">
                <div class="profile-outer">
                    <div class="notification-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="131" height="129" viewBox="0 0 131 129"
                            fill="none">
                            <g filter="url(#filter0_d_7437_64602)">
                                <rect x="19.5684" y="18" width="91.8633" height="89.876" rx="44.938" fill="#E6F0EB" />
                                <rect x="12.7089" y="11.1405" width="105.582" height="103.595" rx="51.7975"
                                    stroke="white" stroke-width="13.719" />
                                <path
                                    d="M51.4367 61.8922C51.2856 64.7618 51.4593 67.8164 48.8955 69.7392C47.7023 70.6342 47 72.0386 47 73.5303C47 75.582 48.607 77.3276 50.7 77.3276H80.3003C82.3932 77.3276 84.0003 75.582 84.0003 73.5303C84.0003 72.0386 83.2981 70.6342 82.1048 69.7392C79.5409 67.8164 79.7146 64.7618 79.5635 61.8922C79.1699 54.4123 72.9902 48.5493 65.5001 48.5493C58.01 48.5493 51.8303 54.4123 51.4367 61.8922Z"
                                    fill="#006736" stroke="#006736" stroke-width="1.41667" stroke-linecap="round" />
                                <path
                                    d="M62.416 44.6949C62.416 46.3978 63.7965 48.5491 65.4994 48.5491C67.2022 48.5491 68.5827 46.3978 68.5827 44.6949C68.5827 42.992 67.2022 42.3823 65.4994 42.3823C63.7965 42.3823 62.416 42.992 62.416 44.6949Z"
                                    stroke="#006736" stroke-width="2" stroke-linecap="round" />
                                <path
                                    d="M71.6694 77.3271C71.6694 80.7331 68.9085 83.4939 65.5027 83.4939C62.0968 83.4939 59.3359 80.7331 59.3359 77.3271"
                                    stroke="#006736" stroke-width="2" stroke-linecap="round" />
                            </g>
                            <defs>
                                <filter id="filter0_d_7437_64602" x="0.36201" y="0.165062" width="130.276"
                                    height="128.29" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="1.3719" />
                                    <feGaussianBlur stdDeviation="2.7438" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix"
                                        result="effect1_dropShadow_7437_64602" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7437_64602"
                                        result="shape" />
                                </filter>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="mailnewsletter-head">
                <h3>Email Notifications.</h3>
                <p>Select the specific types of Newsletter you wish to receive and stay updated effortlessly.</p>
                <div class="d-flex justify-content-end">
                    <button type="button" class="select-all-btn" (click)="selectAll()">
                        {{ checkSelectAll() ? "Unselect All" : "Select All" }}
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-body">
            <div class="content-wrap d-flex flex-column gap-3 gap-lg-4">
                <ng-container *ngFor="let preference of emailPreferences" [formGroup]="emailSettingsForm">
                    <div class="d-flex align-items-center justify-content-between">
                        <div>
                            <h3>{{preference?.name}}.</h3>
                            <p class="mb-0">{{preference?.description}}</p>
                        </div>
                        <div>
                            <label class="switch">
                                <input type="checkbox" [formControlName]="preference.key" (change)="checkSelectAll()">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="d-block text-center modal-footer">
            <div class="row row-gap-3">
                <div class="col-12 col-sm-5">
                    <button class="border-0 cancel-btn" (click)="closeModal.emit()">Cancel</button>
                </div>
                <div class="col-12 col-sm-7">
                    <button class="border-0 save-btn" [ngClass]="{'disabled': apiProcessing}" [disabled]="apiProcessing"
                        (click)="saveSettings()">
                        {{apiProcessing ? 'Processing...' : 'Save Changes.'}}</button>
                </div>
            </div>
            <p>Note: You can update these settings anytime from your account preferences.</p>
        </div>
    </div>
</div>