import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { disconnect, switchNetwork, watchAccount, watchNetwork } from '@wagmi/core';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi';
import { ModalDirective, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { polygon } from 'viem/chains';
import { polygonAmoy } from './chains/polygonAmoy';

import { SocialAuthService } from '@abacritt/angularx-social-login';
import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { FormControl } from '@angular/forms';
import { Idle } from '@ng-idle/core';
import moment from 'moment';
import { ClipboardService } from 'ngx-clipboard';
import { Subject, Subscription, debounceTime, distinctUntilChanged, filter, take, takeUntil } from 'rxjs';
import { IdleService } from 'src/app/core/services/idle.service';
import { CustodialType } from 'src/app/module/dashboard/dashboard.component';
import { CommonService } from 'src/app/shared/services/common.service';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { environment } from 'src/environments/environment';
import { getAddress } from 'viem';
import { BORROWER_NOTIFICATIONS, LENDER_NOTIFICATIONS, NotificationEvent, OTHER_NOTIFICATONS } from '../../constants/notification.const';
import { AccountService } from '../../services/account.service';
import { AnnouncementService } from '../../services/announcement.service';
import { HeightService } from '../../services/height.service';
import { NftService } from '../../services/nft.service';
import { NotificationService } from '../../services/notification.service';
import { SocketService } from '../../services/socket.service';
import { IApiResponse } from '../../utils/common.interface';
import { isUserVerifiedAndValid } from '../../Helper/common-utils';
import { FilterSyncUrlHelper } from '../filters/filter-sync-url';


@Component({
    selector: 'app-header-new',
    templateUrl: './header-new.component.html',
    styleUrls: ['./header-new.component.css'],
    animations: [
        trigger('fadeInOut', [
            // Slide in from right
            transition(':enter', [
                animate('500ms ease-in', keyframes([
                    style({ opacity: 0, transform: 'translateX(100%) scale(0.8)', offset: 0 }),  // Start from outside right
                    style({ opacity: 0.5, transform: 'translateX(20%) scale(0.9)', offset: 0.5 }), // Midway point
                    style({ opacity: 1, transform: 'translateX(0) scale(1)', offset: 1 })  // Fully visible in place
                ]))
            ]),
            // Slide out to right
            transition(':leave', [
                animate('500ms ease-out', keyframes([
                    style({ opacity: 1, transform: 'translateX(0) scale(1)', offset: 0 }),  // Start from current position
                    style({ opacity: 0.5, transform: 'translateX(20%) scale(0.9)', offset: 0.5 }), // Midway point
                    style({ opacity: 0, transform: 'translateX(100%) scale(0.8)', offset: 1 })  // Slide out to right and fade out
                ]))
            ])
        ])
    ]
})
export class HeaderNewComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
    @ViewChild('verificationModal', { static: false }) verificationModal?: ModalDirective;
    @ViewChild('switchNetworkModal', { static: false }) switchNetworkModal?: ModalDirective;
    @ViewChild('announcementContainer') announcementContainer!: ElementRef;
    private resizeSubscription!: Subscription;
    @Output() contentHeightChange = new EventEmitter<number>();
    showProfile: boolean = false;
    showMenu = false;
    @ViewChild('navbarCollapse') navbarCollapse!: ElementRef;
    modalConfig: ModalOptions = {
        animated: true,
        keyboard: false,
        backdrop: 'static',
        ignoreBackdropClick: true
    };
    regulated: boolean = JSON.parse(localStorage.getItem('regulated') || 'null');
    web3Modal: any;
    unwatchAccount: any;
    unwatchNetwork: any;
    account: any = { walletAddress: "", chainId: "", walletProvider: "", networkId: "", init: true };
    user: any;
    connectObservable: any;
    authenticationObservable: any;
    clicked: any;
    regulatedBalance: number = 0;
    maticPrice: number = 0;
    public loaderStatus: boolean = true;
    public loaderObservable: any;
    searchKeyword: string = "";
    searchKeywordObservable: any;
    nftCount: number = 0;
    currencies: any[] = [];
    defaultNetwork: string = 'null'
    notifications: any[] = [];
    notificationCount: number = 0;
    kycStatus: any = {};
    hasUnreadNotifications: boolean = false;
    isNotificationLoader = false;
    recentSearch: Array<any> = [];
    isSearch = false;
    searchControl = new FormControl({ value: '', disabled: true });
    private unsubscribeSearch$ = new Subject<void>();
    isSearchLoading = false;
    isNotification: boolean = false;
    notification: any = {};
    tokenBalanceInterval: any;
    isExpand: boolean = false;
    announceData: any = {}
    private resizeObserver: ResizeObserver | null = null;
    private custodialType = CustodialType;
    showMailVerificationMenu = true;

    /**
     * constructor
     */
    constructor(
        private webStorageService: WebStorageService,
        public router: Router,
        public route: ActivatedRoute,
        private toastr: ToastrService,
        private commonService: CommonService,
        private accountService: AccountService,
        private nftService: NftService,
        private clipboardService: ClipboardService,
        private socketService: SocketService,
        private cdref: ChangeDetectorRef,
        private notificationService: NotificationService,
        private elementRef: ElementRef,
        private authService: SocialAuthService,
        private announce: AnnouncementService,
        private idleService: IdleService,
        private idle: Idle,
        private renderer: Renderer2,
        private heightService: HeightService,
        private filterSyncUrlHelper: FilterSyncUrlHelper

    ) {
    }

    async ngOnInit() {
        this.getAdminAnnouncement();
        this.defaultNetwork = environment.DEFAULT_NETWORK;
        this.loaderObservable = this.accountService.loaderStatus.subscribe((response) => {
            this.loaderStatus = response;
            response ? this.searchControl.disable() : this.searchControl.enable();
            this.cdref.detectChanges();
        })
        this.connectObservable = this.accountService.connectObservable.subscribe((response: any) => {
            if (response) {
                this.web3Modal?.open();
            }
        })

        this.account = this.webStorageService.getLocalStorage('account') != null ? JSON.parse(this.webStorageService.getLocalStorage('account') || '') : this.account;
        this.user = this.webStorageService.getLocalStorage('user') != null ? JSON.parse(this.webStorageService.getLocalStorage('user') || 'undefined') : this.user;
        if (this.user && !this.user?.name) {
            let emailSplit = (this.user.email)?.split('@');
            this.user.name = emailSplit?.[0];
        }

        // wallet connect initialization
        const metadata = {
            name: 'Real World',
            description: 'RealWorld.fi - Marketplace',
            url: environment.WEB_SITE_URL,
            icons: ['https://avatars.githubusercontent.com/u/37784886']
        }
        const chains = environment.ENVNAME === 'PRODUCTION' || environment.ENVNAME === 'PRE_PRODUCTION' ? [polygon] : environment.ENVNAME === 'STAGING' ? [polygon, polygonAmoy] : [polygonAmoy];
        const projectId = environment.WALLET_CONNECT_PROJECT_ID;
        const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })
        this.web3Modal = createWeb3Modal({ wagmiConfig, projectId, chains, themeVariables: { '--w3m-accent': '#006736', '--w3m-z-index': 10000, '--w3m-font-family': 'Source Code Pro', '--w3m-font-size-master': 'sm' } })
        this.searchControl.valueChanges
            .pipe(
                debounceTime(2000)
            )
            .subscribe((value: any) => {
                if (this.searchKeyword != value) this.search(value!);
            });
        this.searchKeywordObservable = this.nftService.searchKeywordObservable.subscribe(async (response: any) => {
            let searchValue = ''
            await this.handleSearchValue(this.router.url.toString(), (search: string) => {
                searchValue = search
                if (response || searchValue) {
                    this.searchControl.setValue(response ? response : searchValue)
                } else {
                    this.searchKeyword = '';
                    this.searchControl.setValue('')
                }
            })
        })

        this.socketService.connect();



        // Using router events to trigger code on every route change
        this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(async (data) => {
            if (data.url.includes('?regulated=')) {
                let urlSplit = data.url.split('?regulated=');
                let regulation = urlSplit[urlSplit.length - 1].includes('&') ? urlSplit[urlSplit.length - 1].split('&')[0] : urlSplit[urlSplit.length - 1];
                let regulationBoolean = (regulation === 'true');
                if (this.regulated != regulationBoolean) {
                    this.regulated = regulationBoolean;
                    await this.webStorageService.setLocalStorage('regulated', this.regulated);
                    disconnect();
                    this.webStorageService.clearItem();
                    this.user = undefined;
                    this.account = { walletAddress: "", chainId: "", walletProvider: "", networkId: "", init: true };
                    this.switchNetworkModal?.hide();
                    this.notificationCount = 0;
                    this.notifications = [];
                }
            } else {
                // regulated / unreguated
                this.regulated = JSON.parse(this.webStorageService.getLocalStorage('regulated') || 'true');
                await this.webStorageService.setLocalStorage('regulated', this.regulated);
            }
            if (this.regulated == false) {
                // wallet connect
                this.web3Modal = createWeb3Modal({ wagmiConfig, projectId, chains, themeVariables: { '--w3m-accent': '#006736', '--w3m-z-index': 10000, '--w3m-font-family': 'Source Code Pro', '--w3m-font-size-master': 'sm' } })
            } else {
                this.getBalance();
            }
        });

        if (this.regulated == false) {
            // Listen the wallet network
            this.unwatchAccount = watchAccount((account) => {
                if (account.isConnected) {
                    setTimeout(() => {
                        this.setAccount();
                    }, 1000);
                } else if (this.account.walletAddress !== '') this.logout();
            });
            this.unwatchNetwork = watchNetwork((network) => {
                setTimeout(() => {
                    this.setAccount();
                }, 1010);
            });
        }
        if (this.regulated && this.router.url != '/sign-in' && this.router.url != '/sign-up') {
            this.getMaticPrice();
            this.getCurrencies();
        }

        if (this.user?._id) {
            this.getNotifications();
            this.socketService.getNotification().subscribe((notification: any) => {
                if (notification) {
                    notification.timeDiff = moment(notification.createdAt).from(moment());
                    notification.data = JSON.parse(notification.data);
                    this.notifications.unshift(notification);
                    // set image based on response
                    if (notification?.event_type !== this._notification.BLOCKED || notification?.event_type !== this._notification.UNBLOCKED) notification.image = this.getNft(notification)?.preview_image
                        ? this.getNft(notification)?.preview_image
                        : (this.isHtmlFile(this.getNft(notification)?.primary_media)
                            ? this.getNft(notification)?.secondary_media[0]
                            : this.getNft(notification)?.primary_media)
                    this.notificationCount += 1;
                    this.notification = notification;
                    this.checkUnreadNotifications(this.notifications);
                    this.isNotification = true;
                    setTimeout(() => {
                        this.closePushnotification();
                    }, 5000);
                }
            });
        }

        this.authenticationObservable = this.accountService.authenticationObservable.subscribe((response: any) => {
            if (!response) {
                this.logout(true);
            }
        })
        this.user?._id && this.getRecentSearch();
        this.nftService.recentSearchObservable.subscribe((response) => {
            if (response) {
                this.user?._id && this.getRecentSearch();
                setTimeout(() => {
                    this.nftService.recentSearchStatus(false);
                }, 1000);
            }

        })
        this.setupResizeObserver();
        this.setCustodialType();
        isUserVerifiedAndValid(this.user, this.verificationModal, this.regulated);
    }

    async ngAfterViewInit() {
        // Handles an edge case where a wallet connects without user data in Local Storage 
        // (e.g., due to a reload interruption). For custodial wallets, triggers disconnection 
        // to prevent an invalid authenticated state.
        if (!this.regulated && !this.user && this.account?.walletAddress) {
            await disconnect()
        }
    }

    setCustodialType() {
        this.commonService.custodialTypeObservable.subscribe(async (response: string) => {
            if (response.length && response === this.custodialType.custodial) {
                this.regulated = true;
                await this.webStorageService.setLocalStorage('regulated', this.regulated);
                this.logout();
            } else if (response === this.custodialType.nonCustodial) {
                this.regulated = false;
                await this.webStorageService.setLocalStorage('regulated', this.regulated);
                this.logout();
            }


        })
    }

    ngAfterViewChecked() {
        this.updateAnnouncementHeight();
    }


    updateAnnouncementHeight() {
        if (this.announcementContainer) {
            const element = this.announcementContainer.nativeElement as HTMLElement;

            // Temporarily remove any height constraint
            this.renderer.setStyle(element, 'height', 'auto');

            // Force a reflow
            void element.offsetHeight;

            // Get the natural height of the content
            const contentHeight = element.scrollHeight;

            // Set the height
            this.renderer.setStyle(element, 'height', `${contentHeight}px`);

            // Update the HeightService with the new height
            this.heightService.setHeaderHeight(contentHeight);

        } else {
            // if announcement bar is disabled in the dapp, this will get called
            this.heightService.setHeaderHeight(0);

        }
    }


    async getBalance() {
        if (this.account.walletAddress != '') {
            let balance = await this.commonService.getNativeBalance(this.account);
            this.regulatedBalance = Number(balance.formatted);
        }
    }

    getMaticPrice() {
        this.commonService.getMaticPrice('matic-network').subscribe((response: any) => {
            this.maticPrice = response['matic-network'].usd;
        });
    }

    public toggleMenu(event: MouseEvent) {
        event.stopPropagation();
        this.showMenu = !this.showMenu;
    }

    @HostListener('window:click', ['$event'])
    onClick(event: MouseEvent) {
        const targetElement = this.navbarCollapse?.nativeElement;

        if (targetElement && event.target) {
            const clickedInside = targetElement.contains(event.target as Node);

            if (!clickedInside) {
                this.showMenu = false;
            }
        }
    }

    async slideToggle() {
        this.regulated = !this.regulated;
        await this.webStorageService.setLocalStorage('regulated', this.regulated);
        this.filterSyncUrlHelper.getAppliedFilterQueries('all');
        this.logout();
    }

    setAccount = async () => {
        let store: any = JSON.parse(await this.webStorageService.getLocalStorage('wagmi.store') || 'null');
        let isConnected: any = JSON.parse(await this.webStorageService.getLocalStorage('wagmi.connected') || 'false');
        if (isConnected) {
            let provider: any = JSON.parse(await this.webStorageService.getLocalStorage('wagmi.connectedRdns') || 'null');
            if (store && store.state?.data && !store.state?.data?.chain.unsupported) {
                let { account, chain } = store?.state.data;
                let { chainId, networkId } = await this.commonService.getNetwork((chain.id).toString());
                if (account !== this.account?.walletAddress || chainId !== this.account?.chainId) {
                    this.idleService.reset();
                    this.account = { walletAddress: getAddress(account), chainId, walletProvider: provider === 'io.metamask' ? 'metamask' : 'walletconnect', networkId };
                    this.webStorageService.setLocalStorage('account', JSON.stringify(this.account));
                    this.checkUserRegistration(account);
                    this.switchNetworkModal?.hide();
                    this.commonService.closeAllModals(false);
                }
            } else {
                this.account = { walletAddress: "", chainId: "", walletProvider: provider === 'io.metamask' ? 'metamask' : 'walletconnect', networkId: "" };
                this.webStorageService.setLocalStorage('account', JSON.stringify(this.account));
                if (store?.state?.data?.chain.unsupported) {
                    this.commonService.closeAllModals(true);
                    this.switchNetworkModal?.show();
                }
            }
        }
    }

    logout(authFailed: Boolean = false) {
        disconnect();
        this.webStorageService.clearItem();
        this.user = undefined;
        this.account = { walletAddress: "", chainId: "", walletProvider: "", networkId: "", init: true };
        this.switchNetworkModal?.hide();
        this.idle.stop();
        this.redirectToHome(authFailed);
        this.socketService.disconnect();
        this.notificationCount = 0;
        this.notifications = [];
        this.accountService.updateAuthentication(true);
        this.authService.authState.subscribe((user) => {
            if (user) {
                this.authService?.signOut();
            }
        });
    }

    switchNetwork(chainId: string) {
        switchNetwork({ chainId: Number(chainId) });
    }

    redirectToHome(authFailed: Boolean = false) {
        if (this.router.url === '/' || authFailed) {
            window.location.reload();
        }
        else this.router.navigate(['/']);
    }


    async checkUserRegistration(walletAddress: any) {
        this.accountService.getUser(walletAddress).subscribe({
            next: (response: any) => {
                let userDetails = response.data;
                Object.keys(userDetails).length > 0 ? this.webStorageService.setLocalStorage('user', JSON.stringify(userDetails)) : this.webStorageService.setLocalStorage('user', null);
                this.user = Object.keys(userDetails).length > 0 ? userDetails : null;
                window.location.reload();
            },
            error: (error) => {
                this.toastr.error('Something went wrong try again later.');
            },
        });

    }


    ngOnDestroy() {
        this.teardownResizeObserver();
        if (this.regulated == false) {
            this.unwatchAccount();
            this.unwatchNetwork();
            this.connectObservable.unsubscribe();
        }
        this.loaderObservable.unsubscribe();
        this.searchKeywordObservable.unsubscribe();
        this.socketService.disconnect();
        this.authenticationObservable?.unsubscribe();

        // Cleanup subscription
        if (this.resizeSubscription) {
            this.resizeSubscription.unsubscribe();
        }
    }

    /**
     * Searchs for nft
     * @param {string} keyWord
     */
    search(keyWord: string) {
        this.nftService.setSearchKeyword(keyWord);
        this.searchKeyword = keyWord;
        this.isSearch = false;
    }

    addOverlay() {
        this.showProfile = true;
        this.getBalance();
        this.getTokenBalance();
        this.tokenBalanceInterval = setInterval(() => {
            this.getBalance();
            this.getTokenBalance();
        }, 10000)
        this.getUserNftsCount();
        this.getKycStatus();
    }

    copyWalletAddress(msg: any) {
        this.clipboardService.copy(msg);
        this.toastr.success('Copied wallet address.');
    }

    getUserNftsCount() {
        this.nftService.getUserNftsCount(this.user._id).subscribe({
            next: (response: any) => this.nftCount = response?.data?.no_of_user_nfts || 0
        })
    }

    getCurrencies() {
        this.commonService.getCurrencies().subscribe({
            next: async (response: any) => {
                this.currencies = response.data.filter((currency: any) => !currency.is_deleted);
                this.getTokenPrice();
            },
            error: (error) => {
                this.toastr.error(error?.data?.message || "Something went wrong, try again later.");
            }
        })
    }

    getTokenBalance() {
        this.currencies.map(async (currency: any, index) => {
            let balance = await this.commonService.getTokenBalance(this.account, currency.address);
            currency.balance = Number(balance.formatted);
            if (this.currencies.length == index + 1) {
                setTimeout(() => {
                    this.currencies.sort((a, b) => b.balance - a.balance);
                }, 500);
            }
        });
    }

    async getTokenPrice() {
        this.currencies.map((currency) => {
            setTimeout(async () => {
                let response: any = await this.commonService.getTokenPrice(currency.address);
                currency.usdValue = response[currency.address.toLowerCase()]?.usd || 0;
            }, 100);
        });
    }

    getNotifications() {
        this.isNotificationLoader = true;
        this.notificationService.getNotifications(this.user._id).subscribe({
            next: (response: any) => {
                this.setNotifications(response.data);
            },
            error: async (error) => {
                this.isNotificationLoader = false;
                if (error?.error?.status_code === 401) {
                    error.shortMessage = "Authentication failed. Login again to continue.";
                    await this.accountService.updateAuthentication(false);
                }
                this.toastr.error(error?.error?.data?.message || error?.data?.message || error.shortMessage || "Something went wrong, try again later.");
            }
        })
    }

    setNotifications(data: any) {
        this.notifications = data.results || [];
        this.notificationCount = data.unread_notification_count;
        this.notifications?.map(notification => {
            notification.timeDiff = moment(notification.createdAt).from(moment());
            if (notification.timeDiff === 'in a few seconds') notification.timeDiff = 'a few seconds ago';
            // set image based on response
            if (notification?.event_type !== this._notification.BLOCKED || notification?.event_type !== this._notification.UNBLOCKED) notification.image = this.getNft(notification)?.preview_image
                ? this.getNft(notification)?.preview_image
                : (this.isHtmlFile(this.getNft(notification)?.primary_media)
                    ? this.getNft(notification)?.secondary_media[0]
                    : this.getNft(notification)?.primary_media)
        })
        this.checkUnreadNotifications(this.notifications);
        this.isNotificationLoader = false;
    }

    readNotification(notification: any) {
        if (notification.read === 0) {
            this.notificationService.readNotification({ notifications: [notification._id] }).subscribe({
                next: (response: any) => {
                    this.setNotifications(response.data);
                    this.redirectNotifications(notification);
                },
                error: (error) => this.toastr.error(error?.data?.message || "Something went wrong, try again later.")
            })
        } else this.redirectNotifications(notification);
    }

    redirectNotifications(notification: any) {
        if (OTHER_NOTIFICATONS.includes(notification.event_type)) {
            const nft = notification.data?.nft || notification.data?.nft_id;
            if (nft?.lazy_mint) {
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                    this.router.navigate([`/lazy-mint/${nft?._id}`], { queryParams: { regulated: this.regulated } })
                });
            } else {
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                    this.router.navigate([`/nft-detail/${nft?.collections?.collection_address ? nft?.collections?.collection_address : nft?.collections}/${nft?.token_id}`], { queryParams: { regulated: this.regulated } })
                });
            }
        }
        if (BORROWER_NOTIFICATIONS.includes(notification.event_type)) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                let id = notification?.data?.loan_request || notification?.data?.loan_request_id?._id || notification.data?._id;
                this.router.navigate([`borrow-detail/${id}`], { queryParams: { regulated: this.regulated } });
            });
        }
        if (LENDER_NOTIFICATIONS.includes(notification.event_type)) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                let id = notification?.data?.loan_request || notification?.data?.loan_request_id?._id || notification.data?._id
                this.router.navigate([`lending-detail/${id}`], { queryParams: { regulated: this.regulated } });
            });
        }
    }

    async getKycStatus() {
        const kycStatus: any = await this.accountService.getKycStatus(this.account?.walletAddress);
        this.kycStatus = kycStatus?.data
    }

    readAllNotification() {
        this.isNotificationLoader = true
        let list = this.notifications.filter((item) => item.read === 0)
        this.notificationService.readNotification({ notifications: list.map(item => item._id) }).subscribe({
            next: (response: any) => {
                this.setNotifications(response.data);
            },
            error: (error) => {
                this.isNotificationLoader = false
                this.toastr.error(error?.data?.message || "Something went wrong, try again later.")
            }
        })
    }

    /**
     * Gets recent search
     */
    getRecentSearch() {
        if (this.user?._id) {
            this.nftService.getRecentSearch(this.user?._id).subscribe((response: any) => {
                this.recentSearch = response.data;
            })
        }
    }

    /**
     * Clicks search
     */
    clickSearch() {
        this.isSearch = true;
        this.isSearchLoading = true;
    }

    @HostListener('document:click', ['$event'])
    clickout(event: Event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.isSearch = false;
            this.isSearchLoading = false;
            if (this.showProfile) this.closeProfile();
        }
    }

    closeProfile() {
        this.showProfile = false;
        clearInterval(this.tokenBalanceInterval);
    }


    /**
     * push notiifcation close
     */
    closePushnotification() {
        this.isNotification = false;
        this.notification = {};
    }

    setRoute() {
        this.webStorageService.setItem('previousRoute', this.router.url)
    }

    expandCollapse(event: any, notification: any) {
        event.stopPropagation();
        notification.isExpand = !notification.isExpand;
    }

    isHtmlFile(url: string): boolean {
        return url ? url.split('.').pop() === 'html' : false;
    }

    getNft(notification: any) {
        return notification?.data?.nft || notification?.data?.nft_id || notification?.data?.collateral_assets?.[0] || notification?.data?.loan_request_id?.collateral_assets?.[0];
    }
    getAdminAnnouncement() {
        this.announce.getAnnouncement().subscribe({
            next: (response: IApiResponse) => {
                this.announceData = response.data;
                if (this.announceData.enabled == true) {
                    document.body.classList.add('enable-status-scroll')
                }
                else {
                    document.body.classList.add('disable-status-scroll')
                }
            },
            error: (response: IApiResponse) => {
                console.log('error');

            }
        })
        this.updateAnnouncementHeight();
    }


    private setupResizeObserver() {
        if ('ResizeObserver' in window) {
            this.resizeObserver = new ResizeObserver(() => {
                this.updateAnnouncementHeight();
                this.cdref.detectChanges();
            });

            if (this.announcementContainer) {
                this.resizeObserver.observe(this.announcementContainer.nativeElement);
            }
        }
    }

    private teardownResizeObserver() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    /**
     * Retrieves the total number of collateral assets from a notification object.
     *
     * @param {Object} notification - The notification object containing data.
     * @returns {number} The total number of collateral assets.
     */
    getTotalCollateralAssets(notification: { data: any }): number {
        return ((notification?.data?.['collateral_assets'] || notification?.data?.['loan_request_id']?.['collateral_assets'] || []) as any[]).length;
    }
    get _notification(): typeof NotificationEvent {
        return NotificationEvent
    }
    checkUnreadNotifications(notifications: any[]) {
        this.hasUnreadNotifications = notifications.some(item => item.read === 0);
    }


    openEmailMenu() {
        this.showMailVerificationMenu = false;
        this.verificationModal?.show();
    }

    /**
     * Handles the extraction and processing of a search value from URL parameters.
     * @param urlParams The URL query string containing filter parameters.
     * @param callBack A function to call with the extracted search value.
     */
    async handleSearchValue(urlParams: string, callBack: (value: string) => void) {
        const decodedUrlParams = decodeURIComponent(urlParams);
        let searchQuery = '';
        const urlSegments = decodedUrlParams.split('/?filter/');

        if (urlSegments.length !== 1) {
            const queryParams = urlSegments[1].split('&');
            const validFilters = this.filterSyncUrlHelper.removeUnwantedParams(queryParams);

            if (validFilters[0].includes('key')) {
                searchQuery = validFilters[0].split('key=')[1];
                callBack(searchQuery);
                return;
            }
        }

        callBack(searchQuery);
    }


    get routerCheck() {
        if (this.router.url == '/' || decodeURIComponent(this.router.url).includes('/?filter/')) {
            return true
        } else {
            this.searchKeyword = ''
            return false
        }
    }
}